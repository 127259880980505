import React from 'react';
import Footer from './components/Footer';
import './HomePage.css';

const HomePage = () => {

  return (
    <div className="container-fluid home">
      <div className="row">
        <div className="col-12 section-1">
          <div className="content">
            <div className='apyce-logo'>
              <img src="/images/site/apyce-logo.png" alt="Logo" />
            </div>
            {/* Desktop */}
            <div className='home-logo mx-auto d-none d-md-block mb-5'>
              <img src="/images/site/home-logo.png" alt="Logo" />
            </div>
            {/* Mobile */}
            <div className='home-logo mobile mx-auto d-block d-md-none'>
              <img src="/images/site/home-logo-mobile.png" alt="Logo" />
            </div>
            <div className='home-button-container mt-1 mx-auto'>
              <a href="/locales" type="button" className='btn btn-home-primary map'>Ingresa al mapa para conocer<br/><span>LOCALES CERCANOS</span></a>
            </div>
            <br/>
            {/*
            <div className='home-button-container mt-1 mx-auto'>
              <a href="/register" type="button" className='btn btn-home-primary'>Si sos propietario de un local <br/><span>INSCRIBILO AQUI</span></a>
            </div>
            <div className='home-link-container mt-1 mx-auto pt-3 pt-md-4'>
              <a href="/login">Si ya estas registrado ingresa acá</a>
            </div>
            */}
            {/* Desktop */}
            {/*
            <div className='home-text-container mx-auto mt-5 d-none d-md-block'>              
              <p className="text-1">No te pierdas esta oportunidad única en el año<br/>para disfrutar tus pizzas favoritas con <strong>50% de descuento</strong> y las empanadas <strong>3x2</strong>.<br />Invita a tus amigos, junta la famila y vení a celebrar el amor por la pizza"</p>
              
              <p className="text-2 mb-5">&#161;Es una fiesta imperdible&#33;</p>
            </div>
            */}
            {/* Mobile */}
            {/*
            <div className='home-text-container mx-auto mt-3 d-block d-md-none'>
              <p className="text-1">No te pierdas esta oportunidad única en el año para disfrutar tus pizzas favoritas con <strong>50% de descuento</strong> y las empanadas <strong>3x2</strong>. Invita a tus amigos, junta a tu familia y vení a celebrar el amor por la pizza</p>
              <p className="text-2 mb-5">&#161;Es una fiesta imperdible&#33;</p>
            </div>
            */}
            {/* Desktop */}    
            {/*        
            <div className='home-text-container mx-auto mt-5 d-none d-md-block'>              
              <p className="text-1">Sumate desde cualquier parte del país y se parte del evento gastronómico del año.<br/>No te quedes afuera de este momento único, donde los locales de pizza y empanadas se llenan de gente.</p>              
              <p className="text-2 mb-5">&#161;Inscribí tu local, es gratis y muy fácil&#33;</p>
            </div>
            */}
            <div className='home-text-container mx-auto mt-5 d-none d-md-block'>              
              <p className="text-1">Si sos fan de la pizza y la empanada, conocé en nuestro mapa interactivo los más de 1.500 locales adheridos de todo el païs.</p>              
              <p className="text-2 mb-5">&#161;Conocé los locales más cercanos a tu ubicación&#33;</p>
            </div>
            {/* Mobile */}            
            <div className='home-text-container mx-auto mt-3 d-block d-md-none'>
            <p className="text-1">Si sos fan de la pizza y la empanada, conocé en nuestro mapa interactivo los más de 1.500 locales adheridos de todo el païs.</p>              
            <p className="text-2 mb-5">&#161;Conocé los locales más cercanos a tu ubicación&#33;</p>
            </div>

            {/*
            <div className='home-map-text text-center mt-1 mx-auto pt-3 pt-md-4'>
             <p>A partir del 12 de Septiembre se va a encontrar disponible el mapa interactivo, para que los fans de la pizza y empanadas puedan consultar locales adheridos.</p>
            </div>
            */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 section-2">
          {/* 
          <div className='home-button-container mb-3 mx-auto mt-md-5'>
            <button type="button" className='btn btn-home-secondary'>Reel Edición 2023</button>
          </div>
          */}
          <div className="title mx-auto mt-3 mt-md-5">
            <h2 className="text-center">Datos Edición 2023</h2>
          </div>
          <div className="last-edition-items d-flex flex-column flex-md-row mx-auto">
            <div className="last-edition-item my-2 my-md-4 text-center">
              <img className="item-image mb-2" src="/images/site/home-icon-1.png" alt="locales participantes" />
              <div className="item-number">1300</div>
              <div className="item-text">Pizzerias</div>
            </div>
            <div className="last-edition-item my-2 my-md-4 text-center">
              <img className="item-image mb-2" src="/images/site/home-icon-2.png" alt="promociones" />
              <div className="item-number">2310</div>
              <div className="item-text">Promociones</div>
            </div>
            <div className="last-edition-item my-2 my-md-4 text-center">
              <img className="item-image mb-2" src="/images/site/home-icon-3.png" alt="agenda" />
              <div className="item-number">100mil</div>
              <div className="item-text">Visitas al sitio</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 section-3">
          <div className='apyce-logo-blue mt-5 mx-auto'>
            <img src="/images/site/apyce-logo-blue.png" alt="Logo apyce blue" />
          </div>
          <div className="text mx-auto mt-4">
            <p className="text-center">Es el evento gastronómico más importantes del año porque la <strong>Pizza</strong> y <strong>Empanada</strong> tienen un lugar muy especial en el corazón de todos los argentinos. Queremos que este día todos puedan disfrutarlas. Seguinos y no te pierdas de nada.</p>
          </div>
          <div className="social mx-auto d-flex flex-row mt-2 mb-2">
            <div className='social-icon-container mx-2'>
              <a href="https://www.facebook.com/" rel="noreferrer" target='_blank'>
                <img src="/images/site/Facebook.png" alt="facebook" />
              </a>
            </div>
            <div className='social-icon-container mx-2'>
              <a href="https://x.com/APPYCE" rel="noreferrer" target='_blank'>
                <img src="/images/site/Twitter.png" alt="facebook" />
              </a>
            </div>
            <div className='social-icon-container mx-2'>
              <a href="https://www.instagram.com/apyce.ar/" rel="noreferrer" target='_blank'>
                <img src="/images/site/Instagram.png" alt="facebook" />
              </a>
            </div>
            <div className='social-icon-container mx-2'>
              <a href="https://www.youtube.com/@apyceArgentina" rel="noreferrer" target='_blank'>
                <img src="/images/site/Youtube.png" alt="facebook" />
              </a>
            </div>
          </div>
          <div className="brands mx-auto mb-5 mt-3">
            <h3 className="title text-center mt-5">Acompañan el evento</h3>
            <div className="d-flex flex-wrap justify-content-center mt-5 mb-5">
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-1-1.png" alt="sponsor" />                
              </div>
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-1-2.png" alt="sponsor" />                
              </div>
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-1-3.png" alt="sponsor" />                
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center mt-4">
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-1.png" alt="sponsor" />                
              </div>
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-2.png" alt="sponsor" />                
              </div>   
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-3.png" alt="sponsor" />                
              </div>      
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-4.png" alt="sponsor" />                
              </div>   
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-5.png" alt="sponsor" />                
              </div>      
              <div className='brand-icon mx-2 mx-md-4'>                
                <img src="/images/site/sponsor-6.png" alt="sponsor" />                
              </div>         
            </div>
          </div>
        </div>
      </div>
      <div className="row">        
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
